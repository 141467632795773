import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addEqu, updateEqu, fetchEquDetail, fetchPropertyList } from "@/api/charge";
import "@/utils/global.js";
import { portList } from "@/utils/util";
import { fetchOccupyTmplList } from "../../../api/charge";
var priceTime = [];

for (var i = 1; i <= 10; i++) {
  var value = i >= 10 ? i : '0' + i;
  var time = value + ':00';

  if (i == 1) {
    time = '00:00';
  }

  priceTime.push({
    value: value,
    price: "",
    service_charge: "",
    time: time
  });
}

export default {
  name: "edit",
  data: function data() {
    var self = this;
    return {
      // 查询字段
      editForm: {
        name: "",
        number: undefined,
        type: "",
        pricing_method: 1,
        protocol: 'miaoxing',
        port_count: "",
        price: 0,
        price_power: [{
          min_power: "",
          max_power: "",
          price: ""
        }],
        price_time: priceTime,
        price_fpv: [{
          value: 'valley',
          label: '低谷时段',
          price: '',
          service_charge: ''
        }, {
          value: 'flat',
          label: '平时段',
          price: '',
          service_charge: ''
        }, {
          value: 'peak',
          label: '高峰时段',
          price: '',
          service_charge: ''
        }, {
          value: 'point',
          label: '尖峰时段',
          price: '',
          service_charge: ''
        }],
        cost: 0,
        min_cost: 0,
        min_power: 0,
        address: "",
        max_power: 0,
        withhold_money: 0,
        delay_time: 20,
        withhold_time: 480,
        property_id: "",
        vendor: "",
        lat: "",
        lng: "",
        control_state: 0,
        work_mode: 0,
        occupy_tmpl_ids: []
      },
      rules: {
        name: [{
          required: true,
          message: "充电桩名称不能为空"
        }],
        number: [{
          required: true,
          message: "充电桩设备ID不能为空"
        }],
        type: [{
          required: true,
          message: "充电桩类型不能为空"
        }],
        pricing_method: [{
          required: true,
          message: "计费方式不能为空"
        }],
        price_power: [{
          required: true,
          message: "充电单价不能为空"
        }],
        price_time: [{
          required: true,
          message: "充电单价不能为空"
        }],
        port_count: [{
          required: true,
          message: "充电口数量不能为空"
        }],
        cost: [{
          required: true,
          message: "最低消费不能为空"
        }],
        min_cost: [{
          required: true,
          message: "不能为空"
        }],
        min_power: [{
          required: true,
          message: "最小功率不能为空"
        }],
        address: [{
          required: true,
          message: "不能为空"
        }],
        max_power: [{
          required: true,
          message: "不能为空"
        }],
        withhold_money: [{
          required: true,
          message: "不能为空"
        }],
        withhold_time: [{
          required: true,
          message: "不能为空"
        }],
        property_id: [{
          required: true,
          message: "不能为空"
        }],
        vendor: [{
          required: true,
          message: "不能为空"
        }],
        lat: [{
          required: true,
          message: "不能为空"
        }],
        lng: [{
          required: true,
          message: "不能为空"
        }],
        control_state: [[{
          required: true,
          message: "桩管理状态不能为空"
        }]]
      },
      //
      editStatus: "add",
      lineStatus: _global.lineStatus,
      chargeType: _global.chargeType,
      billMode: _global.billMode,
      connectMode: _global.connectMode,
      controlState: _global.controlState,
      protocolMode: [{
        value: 'miaoxing',
        label: '秒行'
      }, {
        value: 'yunkuaichong',
        label: '云快充'
      }],
      workMode: [{
        value: 0,
        label: '线上结算'
      }, {
        value: 1,
        label: '刷卡模式'
      }, {
        value: 2,
        label: '即插即用'
      }],
      portList: portList(),
      villageOptions: [],
      pointDialog: false,
      villageLoading: false,
      // map
      mapCenter: [121.59996, 31.197646],
      centerMark: "",
      events: {
        click: function click(e) {
          var _e$lnglat = e.lnglat,
              lng = _e$lnglat.lng,
              lat = _e$lnglat.lat;
          self.mapCenter = [lng, lat]; // 这里通过高德 SDK 完成。

          self.getLocation([lng, lat]);
        }
      },
      plugin: [{
        pName: "ToolBar",
        position: "RB"
      }, {
        pName: "MapType",
        defaultType: 0
      }],
      address: "",
      defaultCursor: "crosshair",
      idArr: [],
      idIndex: 0,
      idNumber: "",
      totalPage: "",
      prePage: "",
      nextPage: "",
      searchForm: "",
      titleDal: ["", "元/半小时", "元/度"],
      occupyTmplLoading: false,
      occupyTmplOptions: []
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    getEquDetail: function getEquDetail(number) {
      var _this = this;

      fetchEquDetail(number).then(function (res) {
        var data = res.data;

        _this.resetEditForm(data);

        var property = data["property"];
        _this.villageOptions = [];

        if (property) {
          _this.villageOptions.push(res.data.property);
        }

        _this.$nextTick(function () {
          _this.$refs["editForm"].clearValidate();
        });
      }).catch(function (error) {
        console.log(error);
      });
    },
    init: function init() {
      var _this2 = this;

      if (this.$route.query && this.$route.query.idArr) {
        console.log(this.$route.query && this.$route.query.idArr);
        this.editStatus = "update";
        var number = this.$route.query.number;
        this.idNumber = this.$route.query.number;
        var arr = this.$route.query && this.$route.query.idArr;
        this.totalPage = arr.length;
        this.idArr = arr;
        this.idIndex = arr.indexOf(String(number));
        this.searchForm = this.$route.query.searchForm;
        console.log(this.idIndex, "index ");
        this.getEquDetail(number);
      }

      fetchOccupyTmplList({}).then(function (res) {
        _this2.occupyTmplOptions = res.data;
      });
    },
    onChangeType: function onChangeType(value) {
      if (value == 1) {
        this.editForm.pricing_method = 2;
      }
    },
    handleSelectStage: function handleSelectStage() {
      var _this3 = this;

      this.editForm.price_fpv.forEach(function (item) {
        _this3.editForm.price_time.forEach(function (row) {
          if (row.stage == item.value) {
            row.price = item.price;
            row.service_charge = item.service_charge;
            return;
          }
        });
      }); // if(value=='peak'){
      //   row.price = this.editForm.price_fpv[0].price;
      //   row.service_charge = this.editForm.price_fpv[0].service_charge;
      // }
    },
    handleAdd: function handleAdd() {
      var _this4 = this;

      console.log("添加设备");
      this.$refs["editForm"].validate(function (valid) {
        if (valid) {
          addEqu(_this4.editForm).then(function (response) {
            _this4.$message({
              type: "success",
              message: "添加设备成功"
            });

            console.log("跳转");

            _this4.$router.push("/charge/equipment/list");
          }).catch(function (err) {
            console.log(err);

            _this4.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      var _this5 = this;

      console.log("修改设备", this.editForm);
      this.$refs["editForm"].validate(function (valid) {
        if (valid) {
          if (_this5.editForm.pricing_method == 3) {
            if (_this5.editForm.price_power.length == 0) {
              _this5.$message({
                type: "warning",
                message: "请输入充电单价"
              });
            }
          }

          updateEqu(_this5.idNumber, _this5.editForm).then(function (response) {
            _this5.$message({
              type: "success",
              message: "修改设备成功"
            });

            console.log("跳转"); // this.$router.push('/charge/equipment/list')
          }).catch(function (err) {
            console.log(err); //this.$message({ type: "warning", message: err.data["message"] });
          });
        }
      });
    },
    // 地图
    onSearchResult: function onSearchResult(pois) {
      console.log(pois);
      var latSum = 0;
      var lngSum = 0;

      if (pois.length > 0) {
        pois.forEach(function (poi) {
          var lng = poi.lng,
              lat = poi.lat;
          lngSum += lng;
          latSum += lat; // this.markers.push([poi.lng, poi.lat]);
        });
        var center = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length
        };
        this.mapCenter = [center.lng, center.lat];
        this.getLocation([center.lng, center.lat]);
      }
    },
    getLocation: function getLocation(poit) {
      var self = this;
      var geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: "all"
      });
      geocoder.getAddress(poit, function (status, result) {
        if (status === "complete" && result.info === "OK") {
          if (result && result.regeocode) {
            self.address = result.regeocode.formattedAddress;
            self.$nextTick();
          }
        }
      });
    },
    selectPointBtn: function selectPointBtn() {
      console.log("选择地点");
      this.editForm.lat = this.mapCenter[0];
      this.editForm.lng = this.mapCenter[1];
      this.pointDialog = false;
    },
    remoteVillage: function remoteVillage(query) {
      var _this6 = this;

      if (query !== "") {
        this.villageLoading = true;
        setTimeout(function () {
          _this6.villageLoading = false;
          var data = {
            keyword: query
          };
          fetchPropertyList(data).then(function (res) {
            _this6.villageOptions = res.data;
          });
        }, 100);
      } else {
        this.villageOptions = [];
      }
    },
    resetEditForm: function resetEditForm(data) {
      var price_power = this.editForm.price_power;

      if (data.price_power.length > 0) {
        price_power = data.price_power;
      }

      this.remoteVillage(data.property_name);
      this.editForm = {
        name: data.name,
        number: data.number,
        type: data.type,
        pricing_method: data.pricing_method,
        port_count: data.port_count,
        price: data.price,
        price_power: price_power,
        price_time: data.price_time,
        cost: data.cost,
        min_cost: data.min_cost,
        min_power: data.min_power,
        address: data.address,
        max_power: data.max_power,
        withhold_money: data.withhold_money,
        delay_time: data.delay_time,
        withhold_time: data.withhold_time,
        property_id: data.property_id,
        vendor: data.vendor,
        lat: data.lat,
        lng: data.lng,
        control_state: data.control_state,
        price_fpv: data.price_fpv,
        protocol: data.protocol,
        work_mode: data.work_mode,
        occupy_tmpl_ids: data.occupy_tmpl_ids
      };
    },
    handleAddItem: function handleAddItem(item) {
      if (item.max_power > 0 && item.price >= 0) {
        var obj = {
          min_power: "",
          max_power: "",
          price: ""
        };
        this.editForm.price_power.push(obj);
      } else {
        return;
      }
    },
    handleDelItem: function handleDelItem(idx) {
      this.editForm.price_power.splice(idx, 1);
    },
    handleChangePoint: function handleChangePoint() {
      this.pointDialog = true; // 经度

      var lat = this.editForm.lat; // 维度

      var lng = this.editForm.lng;

      if (lat && lng) {
        this.mapCenter = [lat, lng];
        this.getLocation([lat, lng]);
      }
    },
    handleBatchPirceTime: function handleBatchPirceTime(value) {
      this.editForm.price_time.forEach(function (item, index) {
        if (index > 0 && item.price != value) {
          item.price = value;
        }
      });
    },
    handleBatchServiceCharge: function handleBatchServiceCharge(value) {
      this.editForm.price_time.forEach(function (item, index) {
        if (index > 0 && item.service_charge != value) {
          item.service_charge = value;
        }
      });
    },
    handlePre: function handlePre(type) {
      var idllist = this.idArr;
      var index = this.idIndex;

      if (type > 0) {
        console.log("下一页");

        if (this.idArr[this.idIndex + 1]) {
          this.idIndex = this.idIndex + 1;
          this.idNumber = this.idArr[this.idIndex];
          this.getEquDetail(this.idArr[this.idIndex]);
        } else {
          console.log("到底了");
          this.$message({
            type: "warning",
            message: "到底了"
          });
        }
      } else {
        console.log("上一页");
        var pre = this.idIndex - 1;

        if (this.idArr[pre] && (pre > 0 || pre == 0)) {
          this.idIndex -= 1;
          this.idNumber = this.idArr[this.idIndex];
          this.getEquDetail(this.idArr[this.idIndex]);
        } else {
          this.$message({
            type: "warning",
            message: "到底了"
          });
        }
      }
    },
    remoteOccupyTmplList: function remoteOccupyTmplList(query) {
      var _this7 = this;

      if (query !== "") {
        this.occupyTmplLoading = true;
        setTimeout(function () {
          _this7.occupyTmplLoading = false;
          var data = {
            keyword: query
          };
          fetchOccupyTmplList(data).then(function (res) {
            _this7.occupyTmplOptions = res.data;
          });
        }, 50);
      } else {
        this.occupyTmplOptions = [];
      }
    },
    goBack: function goBack() {
      console.log(this.searchForm);
      this.$router.push({
        name: "EquipmentList",
        params: {
          searchForm: this.searchForm
        }
      });
    }
  }
};